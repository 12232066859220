@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  margin: 0 auto;
  max-width: 900px;
  padding-block: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  gap: 6.2rem;

  @include respond(mobile) {
    padding-block: 4rem;
    max-width: 100%;
    margin: 0;
    gap: 3.2rem;
  }
}

.title {
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 2.4rem;

  @include respond(tab-port) {
    font-size: 2.8rem;
    line-height: 3rem;
  }
}

.slide {
  & img {
    margin: 0 auto;
    padding-right: 29px;
    max-height: 100px;
    width: auto;

    &:focus-visible {
      outline: $color-white auto 1px !important;
    }

    @include respond(mobile) {
      max-height: 47px;
    }
  }
}
