@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  padding-block: 6.4rem;
  display: flex;
  gap: 1.6rem;
  color: $color-dark-grey-secondary;
  background-color: $color-light;

  @include respond(tab-port) {
    flex-direction: column;
  }
}

.textWrapper {
  display: flex;
  flex-direction: column;
  gap: 9.6rem;
  flex: 1 0 60%;

  @include respond(tab-port) {
    margin: 0 auto;
    max-width: 330px;
    gap: 3rem;
  }
}

.imageWrapper, .imageMobileWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 0 40%;
}

.imageWrapper {
  & img {
    width: 100%;
    height: auto;

    @include respond(tab-port) {
      width: auto;
      height: auto;
    }
  }

  @include respond(tab-port) {
    display: none;
  }
}

.imageMobileWrapper {
  display: none;

  @include respond(tab-port) {
    display: flex;
  }
}

.polish, .english {
  display: flex;
  flex-direction: column;
  gap: 2.6rem;
  
  @include respond(tab-port) {
    text-align: center;
  }
}

.headline {
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 3.4rem;

  @include respond(tab-port) {
    font-size: 2.8rem;
    line-height: 4rem;
  }
}

.paragraph {
  display: flex;
  flex-direction: column;
  gap: 3rem;

  @include respond(tab-port) {
    gap: 3rem;
  }
}

.text, .cta {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 2.4rem;

  & a {
    color: $color-blue-primary;
    text-decoration: underline;

    &:hover {
      color: $color-dark-grey-secondary;
    }
  }

  @include respond(tab-port) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}
