@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  margin-inline: 11%;
  padding-inline: 6.4rem;
  display: flex;
  min-height: 53.6rem;
  width: auto;
  height: auto;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: $color-dark-grey-secondary;

  @include respond(big-desktop) {
    margin-inline: 13%;
  }

  @include respond(tab-land) {
    margin-inline: 9%;
  }

  @include respond(tab-port) {
    margin-inline: 7.5%;
    padding-inline: 5rem;
    padding-block: 3.15rem;
    background-position: 100%;
    min-height: 48rem;
  }

  @include respond(mobile) {
    margin-inline: 6%;
    padding-inline: 2rem;
    flex-direction: column;
    min-height: 34.5rem;
  }
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4.8rem;
  flex: 62%;

  @include respond(tab-port) {
    gap: 2.4rem;
    flex: 50%;
  }

  @include respond(mobile) {
    justify-content: flex-start;
  }
}

.right {
  flex: 38%;

  @include respond(tab-port) {
    flex: 50%;
  }
}

.left,
.right {
  @include respond(mobile) {
    flex: 100%;
  }
}

.top {
  display: flex;
  flex-direction: column;
  max-width: 54rem;
  gap: 3.2rem;

  @include respond(tab-port) {
    gap: 2.4rem;
  }

  @include respond(mobile) {
    max-width: 40rem;
  }
}

.title {
  font-weight: 500;
  font-size: 3.6rem;
  line-height: 5rem;

  @include respond(tab-port) {
    font-size: 2.8rem;
    line-height: 3rem;
  }
}

.description {
  max-width: 38rem;
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 2.6rem;

  @include respond(tab-port) {
    font-size: 1.75rem;
    line-height: 2rem;
  }
}
