@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container, .latestContainer {
  display: flex;
  flex-direction: column;
  color: $color-dark-grey-secondary;
  background-color: $color-white;
}

.container {
  &:hover {
    box-shadow: 0px 2.3936px 7.97868px rgba(0, 0, 0, 0.1);
  }

  &:focus {
    box-shadow: 0px 2.3936px 7.97868px rgba(0, 0, 0, 0.1);
  }
}

.latestContainer {
  flex-direction: row;
  background-color: $color-light;

  @include respond(tablet) {
    flex-direction: column-reverse;
  }
}

.left {
  padding-inline: 3.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 35%;

  @include respond(tablet) {
    padding: 2rem;
  }
}

.right {
  flex: 0 65%;
}

.bottom {
  padding: 2.5rem 1.6rem;

  @include respond(tablet) {
    padding: 2rem 1rem;
  }
}

.date {
  margin-bottom: 1.6rem;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2rem;

  @include respond(tablet) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

.latestDate {
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2.6rem;

  @include respond(tablet) {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.title {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2rem;
  color: $color-dark-grey-secondary;
  text-decoration: none;
  transition: all 0.3s ease;

  &:hover {
    text-decoration: underline;
  }

  @include respond(tablet) {
    font-size: 2rem;
    line-height: 2.2rem;
  }
}

.latestTitle {
  margin-bottom: 1.6rem;
  font-weight: 500;
  font-size: 2.4rem;
  line-height: 2.2rem;

  @include respond(tablet) {
    font-size: 2.4rem;
    line-height: 2.2rem;
  }
}

.excerpt, .latestExcerpt {
  margin-top: 1rem;
  margin-bottom: 1.6rem;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2rem;

  @include respond(tablet) {
    font-size: 1.75rem;
    line-height: 2.2rem;
  }
}

.latestExcerpt {
  margin-top: 0;
}

.featuredImageWrapper {
  position: relative;
  aspect-ratio: 1/1;
  height: 22.8rem;
  width: 100%;

  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  @include respond(tablet) {
    height: 19.8rem;
  }
}

.latestFeaturedImageWrapper {
  position: relative;
  aspect-ratio: 1/1;
  height: 38.2rem;
  width: 100%;

  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  @include respond(tablet) {
    height: 21rem;
  }
}

.link {
  float: right;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 2rem;
  color: $color-dark-grey-secondary;
  transition: all 0.3s ease;

  &:hover {
    font-size: 700;
    transform: scale(1.03);
  }

  @include respond(tablet) {
    font-size: 1.75rem;
    line-height: 2.1rem;
  }
}

.readMore {
  padding: 1.3rem 3.5rem;
  font-size: 1.4rem;

  @include respond(tablet) {
    padding: 1.6rem 3rem;
    font-size: 1.75rem;
    line-height: 1.4rem;
  }
}
