@import '../../../styles/abstracts/mixins';
@import '../../../styles/abstracts/variables';

.tabs {
  width: 100%;
}

.containerWrapper {
  margin-top: 7.4rem;
  margin-bottom: 10.6rem;
  display: flex;
  flex-direction: column;
  gap: 3.4rem;

  @include respond(mobile) {
    margin-bottom: 2.2rem;
  }
}

.avenirFont button {
  font-family: 'Avenir';
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  @include respond(mobile) {
    margin-inline: 0;
  }
}

.productsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-top: 3.2rem;
  gap: 3rem;

  @include respond(tab-port) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  @include respond(mobile) {
    grid-template-columns: 1fr 1fr;
  }
}

.noProductsFound {
  padding-top: 6.2rem;
  padding-bottom: 2rem;
  font-size: 1.6rem;
  line-height: 1.4rem;
  text-align: center;

  @include respond(tab-port) {
    padding-bottom: 2.8rem;
    font-size: 2rem;
    line-height: 1.75rem;
  }
}

.title {
  margin-bottom: 4.2rem;
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 2.4rem;

  @include respond(tab-port) {
    margin-bottom: 2.6rem;
    font-size: 2.8rem;
    line-height: 2.75rem;
    text-align: center;
  }
}

.saleTab {
  color: $color-orange !important;
}

.linkWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
