@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  margin: 0 auto;
  padding-inline: 11%;
  padding-block: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.2rem;

  @include respond(mobile) {
    padding-block: 4rem;
    max-width: 100%;
    margin: 0;
    gap: 3.2rem;
  }
}

.containerNoPaddingY {
  margin: 0 auto;
  padding-inline: 11%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6.2rem;

  @include respond(mobile) {
    max-width: 100%;
    margin: 0;
    gap: 3.2rem;
  }
}
