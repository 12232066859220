@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  margin-bottom: 9.6rem;
  padding-block: 4.8rem;
  color: $color-dark-grey-secondary;
  background-color: $color-light;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  @include respond(tab-port) {
    margin-bottom: 8rem;
    padding-block: 4rem;
    gap: 2rem;
  }
}

.headline {
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 2.4rem;

  @include respond(tab-port) {
    font-size: 2.75rem;
    line-height: 3rem;
  }
}

.content {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.2rem;
  white-space: pre-wrap;

  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1.4em * 9);

  @include respond(tab-port) {
    font-size: 1.9rem;
    line-height: 2.5rem;
  }
}
.showFull {
  display: initial;
  -webkit-line-clamp: unset;
  max-height: none;
  overflow: visible;
  text-overflow: unset;
}

.showMoreContainer {
  margin-top: 1.6rem;
  display: flex;
  justify-content: center;
  width: 100%;

  @include respond(tab-port) {
    margin-top: 2rem;
  }
}

.showMore {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.2rem;
  text-decoration: underline;
  color: $color-dark-grey-secondary;
  background: none;
  border: none;
  text-transform: none;
  cursor: pointer;

  @include respond(tab-port) {
    font-size: 1.9rem;
    line-height: 2.5rem;
  }
}
