@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  flex: 1;

  & img {
    width: 100%;
    height: 100%;
    margin-bottom: 2.4rem;
    aspect-ratio: 1 / 1;

    @include respond(mobile) {
      margin-bottom: 0;
    }
  }

  @include respond(mobile) {
    gap: 1.95rem;
  }
}

.imageWrapper {
  position: relative;
  transition: all 0.5s ease;
  aspect-ratio: 1 / 1;

  &:hover .imageOverlay {
    opacity: 1;
  }
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(51, 51, 51, 0.5);
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  transition: all 0.3s ease;
  aspect-ratio: 1 / 1;
}

.link {
  color: $color-dark-grey-secondary;
  text-decoration: none;
}

.label {
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  text-align: center;

  @include respond(tab-port) {
    font-size: 2.25rem;
    line-height: 2.9rem;
  }
}
