@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.badgesWrapper {
  display: flex;
  gap: 0.4rem;
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  z-index: 9;
}

.soldWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
  background-color: rgba(37, 37, 37, 0.4);
  font-size: 1.3rem;
  color: $color-white;
}

.newBadge,
.saleBadge,
.classicBadge {
  padding-inline: 0.8rem;
  width: max-content;
  height: 2.8rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-blue-primary;
  background-color: $color-white;

  @include respond(tab-port) {
    font-size: 1.6rem;
    line-height: 2.6rem;
  }
}

.newBadge {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
  z-index: 9;
}

.saleBadge {
  color: $color-white;
  background-color: $color-orange;
}

.classicBadge {
  color: $color-dark-grey-secondary;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 0 22.5%;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 0px 5px rgba(37, 37, 37, 0.1);
    cursor: pointer;
  }

  @include respond(big-desktop) {
    flex: 0 18%;
  }

  @include respond(tab-land) {
    flex: 0 22%;
  }

  @include respond(tablet) {
    flex: 0 30%;
  }

  @include respond(tab-port) {
    flex: 0 30%;
  }

  @include respond(mobile) {
    flex: 0 1 47%;
  }
}

.productImage {
  position: relative;
  aspect-ratio: 1/1;
  z-index: -1;

  & img {
    object-fit: cover;
  }
}

.productInfo {
  padding: 1.6rem 0.9rem 3.9rem 0.9rem;
  display: flex;
  flex-direction: column;
  color: $color-dark-grey-secondary;
  gap: 1.6rem;

  @include respond(tab-port) {
    gap: 2.5rem;
  }
}

.title {
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.7rem;
  overflow-wrap: break-word;
  word-wrap: break-word;

  &:hover {
    text-decoration: underline;
  }

  @include respond(tab-port) {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

.price {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.7rem;

  @include respond(big-desktop) {
    font-size: 1.85rem;
    line-height: 1.75rem;
  }

  @include respond(tab-port) {
    font-size: 2.25rem;
    line-height: 1.85rem;
  }
}

.salePriceWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;

  @include respond(tab-port) {
    gap: 1rem;
  }
}

.salePrice {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.7rem;
  color: $color-orange;

  @include respond(big-desktop) {
    font-size: 1.85rem;
    line-height: 1.75rem;
  }

  @include respond(tab-port) {
    font-size: 2.25rem;
    line-height: 1.85rem;
  }
}

.inactivePrice {
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.7rem;
  text-decoration: line-through;

  @include respond(big-desktop) {
    font-size: 1.45rem;
    line-height: 1.75rem;
  }

  @include respond(tab-port) {
    font-size: 1.9rem;
    line-height: 2rem;
  }
}
