@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  padding-top: 10rem;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4.2rem;

  @include respond(tab-port) {
    padding-top: 3.4rem;
    gap: 3rem;
  }
}

.headline {
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 2.4rem;

  @include respond(tab-port) {
    font-size: 2.8rem;
    line-height: 2.75rem;
    text-align: center;
  }
}

.productsWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  gap: 2rem;

  @include respond(tab-port) {
    justify-content: center;
  }

  @include respond(mobile) {
    flex-wrap: wrap;
  }
}

.linkWrapper {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
