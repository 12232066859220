@import '../../styles/abstracts/mixins';
@import '../../styles/abstracts/variables';

.container {
  padding-block: 7.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: $color-dark-grey-secondary;
  background-color: $color-light;
  gap: 2.6rem;

  @include respond(tab-port) {
    background-color: $color-white;
    gap: 2.75rem;
  }
}

.headline {
  font-weight: 500;
  font-size: 2.6rem;
  line-height: 2.4rem;

  @include respond(tab-port) {
    font-size: 2.8rem;
    line-height: 2.75rem;
    text-align: center;
  }
}

.description {
  font-weight: 300;
  font-size: 1.8rem;
  line-height: 2.4rem;

  @include respond(tab-port) {
    padding-inline: 10rem;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
  }

  @include respond(mobile) {
    padding-inline: 0;
    max-width: 35rem;
  }
}

.collectionsWrapper {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 4.7rem;
  row-gap: 3.7rem;

  @include respond(mobile) {
    flex-direction: column;
    gap: 5rem;
  }
}
